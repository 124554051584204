import {
  SELECT_OPTION_TYPE,
  STRING_STRING_HASH,
  STRING_ANY_HASH,
} from '../constants/globals';
import { ClaimResponseEraStatus, EraStatus } from './Era.type';
import { InsuranceEligibilityRequestStatus } from './InsuranceEligibilityRequest.type';
import { InvoiceStatusEnum } from './Invoice.type';

export const ChiroUpIdPattern =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export enum ValidExchangeFunctionsEnum {
  ByType = 'by-type',
  Summary = 'summary',
  Single = 'single',
  Activity = 'activity',
  UIList = 'ui-list',
  Options = 'options',
}

export enum IntegrationExchangeTypeEnum {
  ClaimAcknowledgment = '277',
  ImplementationAcknowledgment = '999',
  File = 'file',
  Remit = 'RMT',
  Claim = 'claim',
  InsuranceEligibilityRequest = '270',
  InsuranceEligibilityResponse = '271',
}

export const AllFileExchangeTypeValues = Object.values(
  IntegrationExchangeTypeEnum,
);

export const IntegrationExchangeTypeOptions: SELECT_OPTION_TYPE[] = [
  {
    text: 'Claim Acknowledgment (277)',
    value: IntegrationExchangeTypeEnum.ClaimAcknowledgment,
  },
  {
    text: 'Implementation Acknowledgment (999)',
    value: IntegrationExchangeTypeEnum.ImplementationAcknowledgment,
  },
  // { text: 'File', value: IntegrationExchangeTypeEnum.File }, // Why upload a standard file??? Attachment maybe?
  { text: 'Remit', value: IntegrationExchangeTypeEnum.Remit },
  { text: 'Claim', value: IntegrationExchangeTypeEnum.Claim },
  {
    text: 'Insurance Eligibility Request (270)',
    value: IntegrationExchangeTypeEnum.InsuranceEligibilityRequest,
  },
  {
    text: 'Insurance Eligibility Response (271)',
    value: IntegrationExchangeTypeEnum.InsuranceEligibilityResponse,
  },
];

const vals = Object.values(IntegrationExchangeTypeEnum);
export const IntegrationExchangeDisplayByType: STRING_STRING_HASH = Object.keys(
  IntegrationExchangeTypeEnum,
)
  .map((key, idx) => ({ text: key, value: vals[idx] }))
  .reduce((acc, cur) => {
    acc[cur.value] = cur.text
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .replace(/ /g, '\u00A0');
    return acc;
  }, {} as STRING_STRING_HASH);

export const IntegrationExchangeTypeByExtension = {
  '277': IntegrationExchangeTypeEnum.ClaimAcknowledgment,
  '27c': IntegrationExchangeTypeEnum.ClaimAcknowledgment,
  '999': IntegrationExchangeTypeEnum.ImplementationAcknowledgment,
  rmt: IntegrationExchangeTypeEnum.Remit,
  '270': IntegrationExchangeTypeEnum.InsuranceEligibilityRequest,
  '271': IntegrationExchangeTypeEnum.InsuranceEligibilityResponse,
};

export enum IntegrationExchangeDispositionEnum {
  Pending = 'pending',
  Transferred = 'transferred',
  Rejected = 'rejected',
  Accepted = 'accepted',
  Processed = 'processed',
  Error = 'error',
  ChecksumFailure = 'checksumFailure',
}

export const AllFileExchangeDispositionValues = Object.values(
  IntegrationExchangeDispositionEnum,
);

const vals2 = Object.values(IntegrationExchangeDispositionEnum);
export const IntegrationExchangeDispositionDisplay: STRING_STRING_HASH =
  Object.keys(IntegrationExchangeDispositionEnum)
    .map((key, idx) => ({ text: key, value: vals2[idx] }))
    .reduce((acc, cur) => {
      acc[cur.value] = cur.text.replace(/([A-Z])/g, ' $1').trim();
      return acc;
    }, {} as STRING_STRING_HASH);

export enum IntegrationExchangeDirection {
  Partner = 'partner',
  S3 = 's3',
  User = 'user',
}

export const FileExchangeDirectionOptions: SELECT_OPTION_TYPE[] = [
  { text: 'Partner', value: IntegrationExchangeDirection.Partner },
  { text: 'Records', value: IntegrationExchangeDirection.S3 },
  { text: 'User', value: IntegrationExchangeDirection.User },
];

export type IntegrationExchangeInsertType = {
  integrationId: number;
  clinicId: number;
  exchangeId: string;
  ts?: number;
  touched?: number;
  userId: string;
  type: IntegrationExchangeTypeEnum;
  source: IntegrationExchangeDirection;
  sourcePath: string;
  target: IntegrationExchangeDirection;
  targetPath: string;
  archive?: string;
  archivePath?: string;
  disposition: IntegrationExchangeDispositionEnum;
  comment: string;
  bytes: number;
  checksum?: string;
  meta?: IntegrationExchangeMetaType;
};

export type IntegrationExchangeType = IntegrationExchangeInsertType & {
  id: number;
  userDisplayName?: string;
  billingProfileId?: number; // Sometimes, we add this to the exchange record. It is not in the table!
  tz?: string;
};

export type IntegrationExchangeMetaActivityType = {
  ts: number;
  userId?: string;
  userDisplayName?: string;
  event: IntegtrationExchangeMetaEventEnum;
  detail: string;
};

export enum IntegtrationExchangeMetaEventEnum {
  Created = 'created',
  Download = 'download',
  SetDisposition = 'set-disposition',
}

export type IntegrationExchangeMetaType = {
  activity?: IntegrationExchangeMetaActivityType[];
};

export enum IntegrationTypeEnum {
  EDI = 'edi',
  ManualEDI = 'manual-edi',
  InsuranceEligibility = 'insurance-eligibility',
}

export const IntegrationTypeDisplay: STRING_STRING_HASH = {
  [IntegrationTypeEnum.EDI]: 'EDI',
  [IntegrationTypeEnum.ManualEDI]: 'Manual EDI',
};

export enum IntegrationsEnum {
  Trizetto = 'Trizetto',
}

export enum IntegrationStepsEnum {
  Ready = 'ready',
  QueuedForFiles = 'queuedForFiles',
  FilesReceived = 'filesReceived',
  NoFilesAvailable = 'noFilesAvailable',
  Off = 'off',
}

export type IntegrationHelperEnqueueContext = {
  integration: IntegrationPartnerType;
  userId: string;
  data: string | Buffer;
};

export enum IntegrationDestinationQueue {
  ClaimsOutbox = 'claims-outbox',
  ManualEDI = 'manual-edi',
  PaperClaims = 'paper-claims',
  InsuranceEligibility = 'insurance-eligibility',
}

export type IntegrationPartnerType = {
  id: number;
  name: string;
  type: IntegrationTypeEnum;
  controlNumber: number;
  testSiteId: string;
  productionSiteId: string;
  specifics: string;
  exchangeVersionCode: string;
  appReceiverCode: string;
  senderIdQualifier: string;
  senderId: string;
  receiverIdQualifier: string;
  receiverId: string;
  default: boolean;
  active: boolean;
  clinicId: number;
  billingProfileId: number;
  secretDecoded: IntegrationSecretType;
  production: boolean;
};

export type IntegrationSecretType = {
  host: string;
  port: number;
  as: string;
  sky: string;
  path: string;
};

export type IntegrationRemoteOptionsType = {
  all?: string;
  trace?: boolean;
  script: IntegrationRemoteScriptType[];
};

export type IntegrationRemoteScriptType = {
  action?: string;
  args?: STRING_ANY_HASH;
};

export type IntegrationSummaryCountRowtype = {
  key: string;
  billingProfileId: number;
  billingProfileName: string;
  hits: number;
};

export type IntegrationSummaryType = {
  counts: IntegrationSummaryCountRowtype[];
};

export type IntegrationInvoiceFilterType = {
  billingProfileId?: string;
  days?: string;
  startDate?: string;
  endDate?: string;
  patientId?: string;
  renderingProviderId?: string;
  payorId?: string;
  status?: InvoiceStatusEnum[];
  automation?: string;
};

export type IntegrationExchangeFilterType = {
  createdDays?: string;
  createdStartDate?: string;
  createdEndDate?: string;
  userId?: string;
  billingProfileId?: string | null;
  disposition?: IntegrationExchangeDispositionEnum[];
  type?: IntegrationExchangeTypeEnum[];
  source?: IntegrationExchangeDirection;
  target?: IntegrationExchangeDirection;
};

export type ClaimManualSearchFilterType = {
  skip?: number;
  limit?: number;
  page?: number;
  search?: string;
  patientId?: string;
  dateStart?: string;
  dateEnd?: string;
  billingProfileId?: number;
};

export type EraFilterType = {
  createdDays?: number;
  createdStartDate?: string;
  createdEndDate?: string;
  status?: EraStatus[];
  controlNumber?: string;
  patientId?: string;
  payorId?: number;
};

export type ClaimResponseFilterType = {
  createdDays?: number;
  createdStartDate?: string;
  createdEndDate?: string;
  userId?: string;
  billingProfileId?: number | string | null;
  status?: ClaimResponseEraStatus[];
  payorId?: number;
  patientId?: string;
};

export type InsuranceEligibilityRequestFilterType = {
  createdDays?: number;
  createdStartDate?: string;
  createdEndDate?: string;
  status?: InsuranceEligibilityRequestStatus[];
  patientId?: string;
  payorId?: string;
};

export const IntegrationDayOptions: SELECT_OPTION_TYPE[] = [
  {
    text: '24 Hours',
    value: '1',
  },
  {
    text: '3 Days',
    value: '3',
  },
  {
    text: '7 Days',
    value: '7',
  },
  {
    text: '30 Days',
    value: '30',
  },
  {
    text: '90 Days',
    value: '90',
  },
  {
    text: 'Range',
    value: '-1',
  },
];

export const IntegrationAutomationOptions: SELECT_OPTION_TYPE[] = [
  { text: '- any -', value: '' },
  {
    text: 'Electronic',
    value: '1',
  },
  {
    text: 'Paper',
    value: '0',
  },
];
