import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import { useDebounce, useOnClickOutside } from '@chiroup/hooks';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { Tooltip } from 'react-tooltip';
import { ErrorIcon, FieldErrors, Input } from '.';
import { Loading } from '../Loading';
import { TrivialTooltip } from '../TrivialTooltip';
import { Virtuoso } from 'react-virtuoso';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export type SelectOption = {
  value: any;
  text: string;
  group?: string;
  disabled?: boolean;
  color?: string;
  data?: any;
};

export type SelectProps = {
  name: string;
  className?: string;
  addContainerClassName?: string;
  label?: string;
  labelClassName?: string;
  tooltip?: string;
  tooltipClassName?: string;
  value?: any;
  onChange: (val: any) => void;
  errors?: FormError;
  options?: any[];
  limit?: number;
  showLabel?: boolean;
  disabled?: boolean;
  alwaysReturnArray?: boolean;
  clearable?: boolean;
  clearOnClose?: boolean;
  loading?: boolean;
  valueField?: string;
  labelField?: string;
  iconField?: string;
  add?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  hint?: string;
  hintOnClick?: () => void;
  native?: boolean;
  autocomplete?: boolean;
  button?: React.ReactNode;
  addInline?: boolean;
  noDarkMode?: boolean;
  hideSelected?: boolean;
  searchField?: string;
  showOptionTooltip?: boolean;
  closeOnSelect?: string[];
  selectedField?: string;
  showTheWholeOptionText?: boolean;
  style?: React.CSSProperties;
  onClear?: () => void;
  inlineSelect?: boolean;
  inlineLabelWidth?: string;
  inlineInputWidth?: string;
  renderLabelP?: boolean;
  componentLabelRight?: React.ReactNode;
};

type SelectItemContentProps = {
  index: number;
  option: any;
  selectOptions: any[];
  labelField: string;
  valueField: string;
  limit?: number;
  noDarkMode?: boolean;
  showTheWholeOptionText?: boolean;
  selectedValueSet: Set<any>;
};

const SelectItemContent = React.memo(
  ({
    index,
    option,
    selectOptions,
    labelField,
    valueField,
    limit,
    noDarkMode,
    showTheWholeOptionText,
    selectedValueSet,
  }: SelectItemContentProps & { selectedValueSet: Set<any> }) => {
    const isActive =
      limit === 1
        ? selectedValueSet.has(option[valueField])
        : selectedValueSet.has(option[valueField]);

    const lastGroup = selectOptions?.[index - 1]?.group;

    return (
      <React.Fragment key={index}>
        {!!option.group && option.group !== lastGroup && (
          <div
            className={[
              'text-gray-400 text-xs uppercase cursor-default select-none relative pt-2 pb-1 pl-3 pr-9',
              noDarkMode ? '' : 'dark:text-darkGray-500',
            ].join(' ')}
          >
            {option.group}
          </div>
        )}
        <Listbox.Option
          key={option[valueField]}
          value={option}
          as="div"
          className={() =>
            classNames(
              option.disabled
                ? 'cursor-not-allowed text-gray-500 bg-gray-100'
                : option.color && !isActive
                  ? option.color
                  : isActive
                    ? 'text-white bg-primary-600'
                    : noDarkMode
                      ? 'text-gray-900'
                      : 'text-gray-900 dark:text-white',
              'cursor-default select-none relative py-2 pl-3 pr-9',
            )
          }
          data-tooltip-id={
            !showTheWholeOptionText
              ? `tooltip-${option[valueField]}`
              : undefined
          }
          data-tooltip-content={
            !showTheWholeOptionText ? option[labelField] : undefined
          }
        >
          {() => (
            <>
              <span
                className={classNames(
                  isActive ? 'font-semibold' : 'font-normal',
                  showTheWholeOptionText ? 'block' : 'block truncate',
                )}
              >
                {option?.component ? option.component : option[labelField]}
              </span>

              {isActive && (
                <span
                  className={classNames(
                    'text-white',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  )}
                >
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              )}
            </>
          )}
        </Listbox.Option>
      </React.Fragment>
    );
  },
);

export const Select = forwardRef<any, SelectProps>(
  (
    {
      name,
      className = '',
      addContainerClassName = '',
      label,
      labelClassName = '',
      tooltip = '',
      tooltipClassName = '',
      value,
      onChange,
      errors,
      options = [],
      limit,
      showLabel = true,
      disabled = false,
      alwaysReturnArray = false,
      clearable = false,
      clearOnClose = false,
      loading,
      valueField = 'value',
      searchField = 'text',
      labelField = 'text',
      iconField,
      add,
      hint,
      hintOnClick,
      native,
      autocomplete,
      button,
      addInline,
      noDarkMode,
      hideSelected = false,
      closeOnSelect,
      selectedField,
      showTheWholeOptionText,
      style = {},
      onClear,
      inlineSelect,
      inlineLabelWidth,
      inlineInputWidth,
      renderLabelP = false,
      componentLabelRight,
      showOptionTooltip = false,
    },
    ref,
  ) => {
    // Ensure we never pass `null` to the native <select>
    if (value === null) value = '';
    const virtuosoRef = useRef(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLInputElement>(null);

    const [rendering, setRendering] = useState(false);
    const [range, setRange] = useState<{
      startIndex: number;
      endIndex: number;
    }>({
      startIndex: 0,
      endIndex: 0,
    });

    // We'll store a timer to hide the spinner after a short delay
    const timerRef = useRef<number | null>(null);

    const [selected, setSelected] = useState<any[]>([]);
    const [allSelectOptions, setAllSelectOptions] = useState<any[]>([]);
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermToUse = useDebounce(searchTerm, 300);

    useOnClickOutside(containerRef, () => {
      if (open) {
        setSearchTerm('');
        onChange(
          limit === 1 && !alwaysReturnArray
            ? (selected || [])?.[0]?.[valueField]
            : (selected || [])?.map((item) => item[valueField]),
        );
        setOpen(false);
        if (clearOnClose) {
          setSelected([]);
        }
      }
    });

    const selectOptions = useMemo(() => {
      const lowerSearchTerm = searchTermToUse.toLowerCase();
      const filteredOptions = allSelectOptions.filter((option) =>
        option[searchField].toLowerCase().includes(lowerSearchTerm),
      );
      if (addInline && filteredOptions.length === 0) {
        return [{ value: { $add: searchTermToUse }, text: 'Add As New' }];
      }
      return filteredOptions;
    }, [allSelectOptions, searchField, searchTermToUse, addInline]);

    useEffect(() => {
      if (!options || options?.length === 0) return;
      setAllSelectOptions(options);
    }, [options]);

    useEffect(() => {
      if (allSelectOptions?.length && valueField) {
        const valuesArr = Array.isArray(value)
          ? value
          : value !== undefined
            ? [value]
            : [];
        const selectedOptions = allSelectOptions.filter((option) =>
          valuesArr.includes(option[valueField]),
        );
        if (addInline && value?.$add) {
          selectedOptions.push(value);
        }
        setSelected(selectedOptions);
      }
    }, [value, addInline, allSelectOptions, valueField]);

    const clear = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      if (disabled) return;
      onChange(limit === 1 && !alwaysReturnArray ? '' : []);
      onClear?.();
    };

    const onChangeValue = (valueObj: any) => {
      const val = valueObj[valueField];
      if (valueObj.disabled) {
        return;
      }
      if (limit === 1) {
        onChange(alwaysReturnArray ? [val] : val);
        setSearchTerm('');
        setOpen(false);
        if (clearOnClose) {
          setSelected([]);
        }
      } else {
        const itsThereIndex = selected.findIndex(
          (item) => item[valueField] === val,
        );
        const newValues = [...selected];
        if (itsThereIndex > -1) {
          newValues.splice(itsThereIndex, 1);
        } else {
          if (closeOnSelect && closeOnSelect.includes(valueObj.value)) {
            setSelected([valueObj]);
            onChange([valueObj.value]);
            setOpen(false);
            return;
          }
          newValues.push(valueObj);
        }

        if (limit && newValues.length <= limit) {
          setSelected(newValues);
        } else if (!limit) {
          setSelected(newValues);
        }
      }
    };

    const removeItem = (val: string) => {
      const newValues = selected
        ?.filter((item) => item[valueField] !== val)
        ?.map((item) => item[valueField]);
      onChange(newValues);
    };

    const getLabelContents = () => {
      return (
        <div className="flex flex-row justify-between">
          <div>
            {renderLabelP ? <p>{label}</p> : <span>{label}</span>}
            {tooltip && (
              <TrivialTooltip text={tooltip} tipClassName={tooltipClassName} />
            )}
          </div>
          {componentLabelRight ? componentLabelRight : null}
        </div>
      );
    };

    const selectedValueSet = useMemo(() => {
      return new Set(selected?.map((item) => item[valueField]));
    }, [selected, valueField]);

    const handleRangeChange = (newRange: {
      startIndex: number;
      endIndex: number;
    }) => {
      const { startIndex: oldStart, endIndex: oldEnd } = range;
      const { startIndex: newStart, endIndex: newEnd } = newRange;
      if (newEnd > oldEnd || newStart < oldStart) {
        setRendering(true);
        if (timerRef.current) {
          window.clearTimeout(timerRef.current);
        }
        timerRef.current = window.setTimeout(() => {
          setRendering(false);
        }, 150);
      }

      setRange(newRange);
    };
    const itemContent = useCallback(
      (index: number, option: any) => {
        return (
          <SelectItemContent
            index={index}
            option={option}
            selectOptions={selectOptions}
            labelField={labelField}
            valueField={valueField}
            limit={limit}
            selectedValueSet={selectedValueSet}
            noDarkMode={noDarkMode}
            showTheWholeOptionText={showTheWholeOptionText}
          />
        );
      },
      [
        selectOptions,
        labelField,
        valueField,
        limit,
        selectedValueSet,
        noDarkMode,
        showTheWholeOptionText,
      ],
    );

    return (
      <div className={className} style={style}>
        {native ? (
          <>
            {showLabel && (
              <div>
                <label
                  htmlFor={name}
                  className={[
                    labelClassName
                      ? labelClassName
                      : 'block text-sm font-medium leading-5 text-gray-900  sm:mt-px sm:pt-2',
                    noDarkMode ? '' : 'dark:text-darkGray-200',
                  ].join(' ')}
                >
                  {getLabelContents()}
                </label>
              </div>
            )}
            <select
              name={name}
              className={[
                'cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5 focus:ring-2',
                errors
                  ? 'border-red-500 text-red-900 placeholder-red-500 focus:border-red-500 focus:ring-red'
                  : 'focus:border-primary-500 focus:ring-primary-500 outline-none',
                disabled
                  ? noDarkMode
                    ? 'text-gray-500 bg-gray-300 cursor-not-allowed'
                    : 'text-gray-500 bg-gray-300 cursor-not-allowed dark:text-darkGray-300 dark:bg-darkGray-500'
                  : '',
                noDarkMode
                  ? ''
                  : 'dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200',
              ].join(' ')}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              disabled={disabled}
            >
              {selectOptions?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </>
        ) : (
          <Listbox value={selected} onChange={onChangeValue}>
            {showLabel && (
              <Listbox.Label
                className={classNames(
                  inlineLabelWidth ?? '',
                  inlineSelect ? `inline-block pr-4` : 'block',
                  labelClassName
                    ? labelClassName
                    : 'text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2',
                  noDarkMode ? '' : 'dark:text-darkGray-200',
                )}
              >
                {getLabelContents()}
              </Listbox.Label>
            )}
            <div
              className={classNames(
                'relative',
                inlineSelect ? 'align-top inline-block' : '',
                inlineInputWidth ?? '',
                addContainerClassName ?? '',
              )}
              ref={containerRef}
            >
              {open && autocomplete ? (
                <Input
                  name="search"
                  className={['w-full', open ? 'block' : 'hidden'].join(' ')}
                  ref={searchRef}
                  onChange={(val) => {
                    setSearchTerm(val);
                  }}
                  value={searchTerm}
                />
              ) : (
                <div
                  onClick={() => {
                    if (!disabled) {
                      setOpen(true);
                      if (autocomplete) {
                        setTimeout(() => {
                          searchRef.current?.focus();
                        }, 0);
                      }
                    }
                  }}
                  ref={ref}
                  className={[
                    'flex flex-row gap-2',
                    open && autocomplete ? 'hidden' : 'block',
                  ].join(' ')}
                >
                  <Listbox.Button
                    className={[
                      'w-full',
                      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    ].join(' ')}
                  >
                    {button || (
                      <div
                        style={{ minHeight: 38 }}
                        className={[
                          'bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm',
                          noDarkMode
                            ? ''
                            : 'dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200',
                          disabled
                            ? 'text-gray-500 bg-gray-300 cursor-not-allowed'
                            : '',
                        ].join(' ')}
                      >
                        <span
                          className={[
                            'block',
                            Object.keys(selected).length && !hideSelected
                              ? ''
                              : 'text-gray-400',
                          ].join(' ')}
                        >
                          {(!limit || limit > 1) &&
                          Object.keys(selected).length &&
                          !hideSelected ? (
                            <div className="flex flex-row gap-2 flex-wrap">
                              {selected?.map((item) => (
                                <span
                                  key={item[valueField]}
                                  className={`inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-primary-100 text-primary-700 ${
                                    disabled ? 'bg-gray-300' : ''
                                  }`}
                                >
                                  {item[labelField]}
                                  <div
                                    className={`flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-primary-400 ${
                                      !disabled
                                        ? 'hover:bg-primary-200 hover:text-primary-500 focus:outline-none focus:bg-primary-500 focus:text-white'
                                        : ''
                                    }`}
                                  >
                                    <span className="sr-only">Remove</span>
                                    {!disabled && (
                                      <svg
                                        className={[
                                          'h-2 w-2',
                                          disabled
                                            ? 'cursor-not-allowed'
                                            : 'cursor-pointer',
                                        ].join(' ')}
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 8 8"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (!disabled) {
                                            removeItem(item[valueField]);
                                          }
                                        }}
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeWidth="1.5"
                                          d="M1 1l6 6m0-6L1 7"
                                        />
                                      </svg>
                                    )}
                                  </div>
                                </span>
                              ))}
                            </div>
                          ) : Object.keys(selected)?.length && !hideSelected ? (
                            addInline && selected[0]?.$add ? (
                              `${selected[0]?.$add}`
                            ) : (
                              `${selected[0][selectedField || labelField]}`
                            )
                          ) : showLabel ? (
                            ''
                          ) : (
                            label
                          )}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                          {loading ? (
                            <Loading
                              color="text-gray-400"
                              className="animate-spin text-gray-400 absolute right-2 top-2"
                              size={5}
                            />
                          ) : errors ? (
                            <ErrorIcon />
                          ) : (
                            <>
                              {clearable &&
                                !!(Array.isArray(value)
                                  ? value.length
                                  : typeof value === 'boolean'
                                    ? true
                                    : value) && (
                                  <svg
                                    className="h-4 w-4 text-gray-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    onClick={clear}
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                )}
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400 pointer-events-none"
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </span>
                      </div>
                    )}
                  </Listbox.Button>
                  {add && !disabled && (
                    <svg
                      className={[
                        'h-6 w-6 text-gray-300 hover:text-gray-400 cursor-pointer self-center',
                        noDarkMode
                          ? ''
                          : 'dark:text-darkGray-600 dark:hover:text-darkGray-500',
                      ].join(' ')}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      onClick={add}
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              )}
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={[
                    'absolute z-50 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm text-left',
                    noDarkMode
                      ? ''
                      : 'dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-white',
                    autocomplete ? '' : 'max-h-60',
                  ].join(' ')}
                >
                  <div className="relative">
                    <Virtuoso
                      ref={virtuosoRef}
                      data={selectOptions || []}
                      totalCount={selectOptions?.length || 0}
                      itemContent={itemContent}
                      rangeChanged={handleRangeChange}
                      style={{ height: '15rem' }}
                      initialItemCount={
                        selectOptions?.length < 10 ? selectOptions?.length : 10
                      }
                      // increaseViewportBy={800}  TOO SLOW FOR SUPER LONG OPTIONS LISTS
                      defaultItemHeight={40}
                    />
                    {rendering && (
                      <div className="absolute right-2 top-2 p-1">
                        <Loading
                          color="text-gray-400"
                          className="animate-spin text-gray-400 absolute right-2 top-2"
                          size={5}
                        />
                      </div>
                    )}
                  </div>
                </Listbox.Options>
              </Transition>
            </div>
            {showOptionTooltip &&
              (selectOptions ?? []).map((option) => (
                <Tooltip
                  key={option[valueField]}
                  id={`tooltip-${option[valueField]}`}
                  place="right"
                  className="z-100 bg-darkGray-600 bg-opacity-60"
                />
              ))}
          </Listbox>
        )}
        {hint ? (
          inlineSelect ? (
            <div className="flex flex-row">
              <div className={inlineLabelWidth}></div>
              <div
                className={[
                  'text-sm',
                  inlineInputWidth,
                  hintOnClick
                    ? 'text-primary-600 font-semibold hover:text-primary-500 cursor-pointer'
                    : 'text-gray-500',
                ].join(' ')}
                id={`${name}-hint`}
                onClick={hintOnClick}
              >
                {hint}
              </div>
            </div>
          ) : (
            <p
              className={[
                'text-sm',
                hintOnClick
                  ? 'text-primary-600 font-semibold hover:text-primary-500 cursor-pointer'
                  : 'text-gray-500',
              ].join(' ')}
              id={`${name}-hint`}
              onClick={hintOnClick}
            >
              {hint}
            </p>
          )
        ) : null}
        <FieldErrors errors={errors} />
      </div>
    );
  },
);

export default Select;
