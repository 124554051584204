import { Dayjs } from 'dayjs';
import { Connection } from 'mysql2/promise';
import { v4 } from 'uuid';
import { AppointmentClass } from './Appointment.class';
import {
  AppointmentInsuranceType,
  AppointmentStatuses,
} from './Appointment.type';
import { CodeSets } from './BillingCode.type';
import { Invoice } from './Invoice.type';
import { getPatientDisplayName, Patient } from './Patient.type';
import {
  PatientPayment,
  PaymentType,
  PayorPaymentTypesHash,
  TransactionPayment,
} from './PatientPayment.type';
import { Payor } from './Payor.type';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { getUserDisplayName } from './User.type';
import { ACTIVITY_CHANGE_TYPE } from '../constants/globals';
import { ChiroUpDayJsCommon } from '../constants/stringConstants';
import { CaseSubType } from '../enums/CaseSubType.enum';
import { ChiroUpJSON } from '../functions/ChiroUpJSON';
import { NotFoundError, BadRequestError } from '../functions/errors';
import { getUser } from '../functions/getProvider';
import { createDayjs } from '../functions/time';
import { Insurance } from './PatientInsurance.type';

dayjs.extend(utc);
dayjs.extend(tz);

export type ConsolidatedTransactionGetResponse = {
  items: PatientTransaction[];
  policies: Insurance[];
  patient: Patient;
};

export type PatientBalanceTransferProps = {
  amount?: number;
  referenceNumber?: string;
  date?: string | undefined;
  description?: string;
  locationId?: number;
};

export type CreditTransactionsNeedUpdatedType = {
  billingKey: string;
  item: PatientTransactionItemType;
};

/**
 * This is the minimal information to build a transaction.
 */
export type TransactionPatientSummaryType = {
  id: string;
  status: AppointmentStatuses;
  billingKey?: string;
  clinicId?: number;
  locationId?: number;
  appointmentDate?: Date | Dayjs | number | string;
  fname: string;
  lname: string;
  pname?: string;
  phone: string;
  email: string;
  displayName: string;
  clinician?: {
    id?: string;
    name?: string;
    supervisedId?: string;
    supervisedName?: string;
  };
  profilePhoto?: string;
  dateOfBirth?: string;
  accountNumber?: string;
  treatmentId?: number;
  disciplineId?: number;
};

export enum TransactionTypeEnum {
  Hybrid = 'Hybrid',
  Encounter = 'Encounter',
  AdHoc = 'AdHoc',
  Payment = 'Payment',
  Credit = 'Credit',
}

export const isaDebitTransactionType = (t: PatientTransaction) => {
  return (
    t.type === TransactionTypeEnum.Hybrid ||
    t.type === TransactionTypeEnum.AdHoc ||
    t.type === TransactionTypeEnum.Encounter
  );
};

export enum TransactionPurchaseSubtypeEnum {
  BalanceTransfer = 'BalanceTransfer',
  PurchasePackage = 'PurchasePackage',
}

export type TransactionPatientBriefSummaryType = Partial<
  Omit<TransactionPatientSummaryType, 'status'>
>;

export type ProviderOnTransactionType = {
  id: string;
  displayName: string;
  supervisedId?: string;
  supervisedName?: string;
  usingPrimary?: boolean | null;
};

export type TransactionSummaryType = {
  notes: string[];
  items?: string[];
  deletedItems?: Partial<PatientTransactionItemType>[];
};

export type TransactionActionType = {
  id?: string | null;
  ts?: number | null;
  displayName?: string | null;
};

export enum TransactionCreditMethodEnum {
  Cash = 'Cash',
  Check = 'Check',
  Credit = 'Credit',
  ACH = 'ACH',
}

export enum TransactionItemSubtypeEnum {
  AdditionalFee = 'AdditionalFee',
  Adjustment = 'Adjustment',
  AdjustmentPackage = 'AdjustmentPackage', // This subtype is a working subtype used in tax calculation but should never actually be written to the database as an item subtype
  AppliedPayment = 'AppliedPayment',
  BalanceAllocatedToPatient = 'BalanceAllocatedToPatient',
  BalanceTransfer = 'BalanceTransfer',
  Canceled = 'Canceled',
  IncentivePayment = 'IncentivePayment',
  NoShow = 'NoShow',
  Override = 'Override',
  Package = 'Package',
  PatientCredit = 'PatientCredit',
  PatientPayment = 'PatientPayment',
  PatientRefund = 'PatientRefund',
  PatientService = 'PatientService',
  PatientWriteOff = 'WriteOff',
  Payment = 'Payment',
  PayorWriteOff = 'PayorWriteOff',
  ProcessingFee = 'ProcessingFee',
  RefundUnappliedPayment = 'RefundUnappliedPayment',
  Reprocessed = 'Reprocessed',
  Service = 'Service',
  Supplement = 'Supplement',
  Tax = 'Tax',
  Treatment = 'Treatment',
}

export const paymentSubtypes = [
  TransactionItemSubtypeEnum.PatientPayment,
  TransactionItemSubtypeEnum.Payment,
  TransactionItemSubtypeEnum.AppliedPayment,
];

export const TransactionItemSubtypeWriteOffs = {
  [TransactionItemSubtypeEnum.PatientWriteOff]:
    TransactionItemSubtypeEnum.PatientWriteOff,
  [TransactionItemSubtypeEnum.PayorWriteOff]:
    TransactionItemSubtypeEnum.PayorWriteOff,
};
export const TransactionItemSubtypeAdjustments = {
  [TransactionItemSubtypeEnum.Adjustment]:
    TransactionItemSubtypeEnum.Adjustment,
  [TransactionItemSubtypeEnum.Override]: TransactionItemSubtypeEnum.Override,
};

export const TransactionItemSubtypePayments = {
  [TransactionItemSubtypeEnum.Payment]: TransactionItemSubtypeEnum.Payment,
  [TransactionItemSubtypeEnum.PatientPayment]:
    TransactionItemSubtypeEnum.PatientPayment,
  [TransactionItemSubtypeEnum.AppliedPayment]:
    TransactionItemSubtypeEnum.AppliedPayment,
} as { [key: string]: string };

export const TransactionItemSubtypeTax = {
  [TransactionItemSubtypeEnum.Tax]: TransactionItemSubtypeEnum.Tax,
};

export const TransactionItemSubtypeService = {
  [TransactionItemSubtypeEnum.Service]: TransactionItemSubtypeEnum.Service,
  [TransactionItemSubtypeEnum.PatientService]:
    TransactionItemSubtypeEnum.PatientService,
} as { [key: string]: string };

export const isaServiceItem = (
  item: PatientTransactionItemType | null | undefined,
) => {
  if (!item) return false;
  if (!item.subtype) return false;
  return !!TransactionItemSubtypeService[item.subtype];
};

export const isaPaymentItem = (
  item: PatientTransactionItemType | null | undefined,
) => {
  if (!item) return false;
  if (!item.subtype) return false;
  return Boolean(TransactionItemSubtypePayments[item.subtype]);
};

export const isNotaServiceItem = (
  item: PatientTransactionItemType | null | undefined,
) => {
  return !isaServiceItem(item);
};

export const isaInsuranceBillableItem = (
  item: PatientTransactionItemType | null | undefined,
) => {
  return item?.subtype === TransactionItemSubtypeEnum.Service;
};

type TransactionItemSubtypeKey = keyof typeof TransactionItemSubtypeEnum;

export const itemSubtypeRename: {
  [key in TransactionItemSubtypeKey]?: string;
} = {
  Adjustment: 'Discount',
  PatientPayment: 'Patient Payment',
  PatientRefund: 'Patient Refund',
  PatientWriteOff: 'Write Off',
  PatientCredit: 'Patient Credit',
  Supplement: 'Supplement or Supply',
  NoShow: 'No-Show',
};

export enum TransactionItemTypeEnum {
  Credit = 'Credit',
  Debit = 'Debit',
  Void = 'Void',
}

export type TransactionItemNoteType = {
  notes: string[];
};

export enum AdjustmentStructureEnum {
  DollarDiscount = '$ Discount',
  DollarOverride = '$ Override',
  PercentDiscount = '% Discount',
}

// sql/ddl/PatientTransactionItem.my.sql
export type PatientTransactionItemType = {
  id: number;
  // paymentId?: number | null;
  // payorReferenceId?: string | null;
  adjustment?: { percentOff?: number; structure: string };
  adjustmentId?: number | null;
  amount: number | string | null;
  code?: string | null;
  codeSet?: string | null;
  compensationAmount?: number;
  compensationRate?: number;
  compensationRateId?: number;
  createdAt?: Date | string;
  description?: string | null;
  diagnoses?: PatientTransactionItemDiagnosisType[] | null;
  displayData?: any;
  groupId?: string | null;
  insuranceBillable?: boolean;
  invoiceId?: number | null;
  isDuplicate?: boolean;
  lineItemControlNumber?: string | null;
  locked?: boolean | false;
  method?: TransactionCreditMethodEnum | null;
  modifier1?: string | null;
  modifier2?: string | null;
  modifier3?: string | null;
  modifier4?: string | null;
  notes?: string[] | null;
  packageDate?: number | string | null;
  packageId?: number | null;
  patientPackageId?: number | null;
  patientTransactionId: number;
  paymentSource?: string;
  paymentToward?: string;
  payorId?: number | null;
  payorName?: string | null;
  payors?: Payor[] | null;
  productTaxRate?: number;
  salesTax?: boolean;
  serviceTaxRate?: number;
  siblings?: number[] | null;
  subtype: TransactionItemSubtypeEnum | null;
  supplementId?: number;
  taxAmount?: number | string | null;
  type: TransactionItemTypeEnum | null;
  units: number | null;
  variableBilledAmount?: boolean;
};

// sql/ddl/PatientTransactionItemDiagnosis.my.sql
export type PatientTransactionItemDiagnosisType = {
  id: number;
  itemId: number;
  code: string;
  codeSet: CodeSets;
  description: string;
  ord: number;
};

// Path: sql/ddl/PatientTransaction.my.sql
/**
 * When one of these is being viewed in the UI, the items
 * are done separately.
 */
export type PatientTransactionSummary = {
  id: number;
  number?: string | null;
  billingKey: string;
  clinicId: number;
  locationId: number;
  type: TransactionTypeEnum;
  subtype?: PaymentType | null;
  balance?: number;
  startingBalance?: number;
  processingFee?: number;
  created: TransactionActionType;
  updated: TransactionActionType;
  patient?: PatientOnTransactionType;
  provider?: ProviderOnTransactionType;
  summary?: TransactionSummaryType;
  description?: string;
};

export type TransactionAppendItemsType = {
  services?: PatientTransactionItemType[];
  insurances?: AppointmentInsuranceType[] | Partial<AppointmentInsuranceType>[];
  courtesyBilling?: boolean;
  superBill?: boolean;
  newTransaction?: PatientTransaction;
};

export type PatientTransaction = PatientTransactionSummary & {
  // [key: string]: any;
  allocateToPatient?: boolean;
  allocatedFromClaimsAmount?: number;
  allowBillingPriorityChange?: boolean;
  amount?: number;
  assessmentCodes?: any[];
  billingProfileId?: number;
  caseSubtype?: CaseSubType | null;
  caseTypeId?: number | null;
  courtesyBilling?: boolean;
  disciplineId?: number | null;
  ePayBatchKey?: string;
  ePayRefNum?: string;
  hasAppointment?: boolean;
  hasEncounter?: boolean;
  encounterSigned?: boolean;
  insurances?: AppointmentInsuranceType[] | Partial<AppointmentInsuranceType>[];
  isBillingStarted?: boolean;
  isVoided?: boolean;
  items: PatientTransactionItemType[];
  locked?: boolean;
  nextRequestedEncounterDate?: number;
  patientBalance?: number | string;
  patientBilledAmount?: number | string;
  patientCaseTypeId?: number | null;
  patientSummary?: TransactionPatientBriefSummaryType | null;
  payments?: PatientPayment[];
  payorId?: number | null;
  payors?: Payor[];
  productTaxRate?: number;
  providerSummary?: any;
  receiptDetail?: ReceiptDetails;
  referenceNumber?: string;
  services?: PatientTransactionItemType[];
  servicesDirty?: boolean | null;
  serviceTaxRate?: number;
  status?: AppointmentStatuses | null;
  superBill?: boolean;
  taxDirty?: boolean | null;
  total?: number;
  transactionDate?: number | null;
  treatmentId?: number | null;
  tz?: string;
  verified?: number | null;
  verifiedBy?: string | null;
  appointmentClinicianId?: string | null;
  merged?: string | null;
};

export const TransactionItemTypeOptions = Object.keys(
  TransactionItemTypeEnum,
).map((key) => ({ text: key, value: key }));

/**
 * This is the raw row from the database table.
 */
export type PatientTransactionRowType = {
  id: number;
  number?: string | null;
  status?: string;
  taxDirty?: boolean;
  servicesDirty?: boolean | null;
  billingKey: string;
  clinicId: number;
  locationId: number;
  tz?: string;
  type?: TransactionTypeEnum;
  subtype?: PaymentType | null;
  balance?: number;
  startingBalance?: number;
  processingFee?: number;
  createdId?: string | null;
  updatedId?: string | null;
  patientId?: string | null;
  patientSummary?: string;
  providerId?: string;
  providerSummary?: string;
  summary?: string;
  createdAt?: number | null;
  updatedAt?: number | null;
  transactionDate: number;
  productTaxRate?: number;
  serviceTaxRate?: number;
  ePayRefNum?: string;
  ePayBatchKey?: string;
  referenceNumber?: string;
  description?: string;
  disciplineId?: number | null;
  treatmentId?: number | null;
  courtesyBilling?: boolean;
  superBill?: boolean;
  billingProfileId?: number;
  hasEncounter?: boolean;
  hasAppointment?: boolean;
  encounterSigned?: boolean;
  patientCaseTypeId?: number | null;
  caseTypeId?: number | null;
  caseSubtype?: CaseSubType | null;
  nextRequestedEncounterDate?: number;
  verified?: number | null;
  verifiedBy?: string | null;
  appointmentClinicianId?: string | null;
  merged?: string | null;
};

export enum TransactionActivityType {
  Created = 'transaction-created',
  Updated = 'transaction-updated',
  PayorInvoiced = 'payor-invoiced',
}

export type PatientTransactionActivity = {
  id?: string;
  billingKey: string;
  clinicId: number;
  createdAt?: number;
  updated: TransactionActionType;
  description: string;
  type: TransactionActivityType;
  data?: {
    updates?: ACTIVITY_CHANGE_TYPE[];
    strings?: string[];
  };
};

/**
 * This is a special-case of a PaymentTransactionItem. Simplifies
 * the payment view.
 */
export type PatientPaymentType = {
  id: number;
  number?: string | null;
  billingKey: string;
  amount: number;
  processingFee: number;
  balance: number;
  paymentDate: string;
  isRefunded?: boolean;
  isPending?: boolean;
  isVoided?: boolean;
  description: string;
  type: TransactionTypeEnum;
  receiptDetail: ReceiptDetails;
};

export type getPatientTransactionOptionsType = {
  rowOnly?: boolean;
  include?: {
    allowedAmounts?: boolean;
    assessmentCodes?: boolean;
    diagnoses?: boolean;
    insurance?: boolean;
    insuranceBillableItems?: boolean;
    invoices?: boolean;
    items?: boolean;
    payments?: boolean;
    payors?: boolean;
    services?: boolean;
  };
};

export type PatientTransactionSaveType = {
  ddb?: any;
  connection: Connection;
  updated: TransactionActionType;
  transaction: PatientTransaction;
  previousTransaction?: PatientTransaction; // We can pass an old one if we've just gotten it.
  insurances?: AppointmentInsuranceType[];
  savingAsNew?: boolean;
  trace?: boolean;
  mockError?: boolean;
  syncPatientSummary?: boolean;
  forceUpdate?: boolean;
  noShowAppointment?: boolean;
  bypassInsuranceRulesCheck?: boolean;
  timed?: boolean;
  calledBy?: string;
  getPatientTransactionOptions?: getPatientTransactionOptionsType;
  skipSaveIfNotDirty?: boolean;
  purpose?: 'update' | undefined | null;
};

// export type PatientTransactionPayment = PatientTransactionItemType & {
//   paymentAmount: number;
//   transactionId: number;
//   summary?: { items: string[] };
// }
export type ReceiptDetails = {
  summary: {
    paymentTotal: number;
    total: {
      amount: number;
      label: string;
    };
    method: {
      type: string;
      label: string;
    };
    totalApplied: number;
    invoiceTotals: number;
    remainingBalance: number;
    paymentDate: string;
    processingFee: {
      label: string;
      amount: number;
    };
  };
  transactionPayments: TransactionPayment[];
  invoices: Partial<Invoice>[];
  isRefunded: boolean;
  isVoided: boolean;
  number?: string;
};

export type PatientOnTransactionType = {
  id: string;
  fname?: string;
  lname?: string;
  phone?: string;
  email?: string;
  displayName: string;
  profilePhoto?: string;
  dateOfBirth?: string;
  accountNumber?: string;
};
export class PatientTransactionClass implements PatientTransaction {
  id: number;
  number?: string | null;
  transactionDate?: number | null;
  status?: AppointmentStatuses | null;
  taxDirty?: boolean | null;
  servicesDirty?: boolean | null;
  billingKey: string;
  clinicId: number;
  locationId: number;
  balance?: number;
  startingBalance?: number;
  // processingFee?: number;
  type: TransactionTypeEnum;
  subtype: PaymentType | null;
  created: TransactionActionClass;
  updated: TransactionActionClass;
  patient?: PatientOnTransactionType;
  provider?: ProviderOnTransactionType;
  description?: string;
  items: any[]; // Can't stand it, had to go with any.
  summary: any;
  patientBalance?: number | string;
  patientBilledAmount?: number | string;
  courtesyBilling?: boolean;
  superBill?: boolean;
  billingProfileId?: number;
  onClick: (e: any, s: string) => void;
  tz?: string;
  patientCaseTypeId?: number | null;
  caseTypeId?: number | null;
  caseSubtype?: CaseSubType | null;
  nextRequestedEncounterDate?: number;
  verified?: number | null;
  verifiedBy?: string | null;
  appointmentClinicianId?: string | null;

  constructor(
    row: PatientTransactionRowType,
    items: PatientTransactionItemType[],
    onClick?: (billingKey: string) => void,
  ) {
    this.id = row.id;
    this.number = row.number;
    this.transactionDate = row.transactionDate;
    this.clinicId = row.clinicId;
    this.status = row.status as AppointmentStatuses;
    this.taxDirty = row.taxDirty;
    this.servicesDirty = row.servicesDirty;
    this.locationId = row.locationId;
    this.billingKey = row.billingKey;
    this.type = row.type as TransactionTypeEnum;
    this.subtype = row.subtype as PaymentType;
    this.balance = row.balance;
    this.startingBalance = row.startingBalance;
    this.nextRequestedEncounterDate = row.nextRequestedEncounterDate;
    this.verified = row.verified;
    this.verifiedBy = row.verifiedBy;
    // this.processingFee = row.processingFee;
    this.created = new TransactionActionClass({
      id: row.createdId,
      ts: row.createdAt,
    });
    this.updated = new TransactionActionClass({
      id: row.updatedId,
      ts: row.updatedAt,
    });
    this.patient =
      typeof row.patientSummary === 'string'
        ? ChiroUpJSON.parse(row.patientSummary)
        : row.patientSummary;
    this.provider =
      typeof row.providerSummary === 'string'
        ? ChiroUpJSON.parse(row.providerSummary)
        : row.providerSummary;
    this.courtesyBilling = row.courtesyBilling;
    this.superBill = row.superBill;
    this.items = items;
    this.billingProfileId = row.billingProfileId;
    if (onClick) {
      this.onClick = (_: any, s: string) => {
        onClick(s);
      };
    } else {
      this.onClick = () => {
        console.warn('no onClick');
      };
    }
    this.tz = row.tz;
    this.patientCaseTypeId = row.patientCaseTypeId;
    this.caseTypeId = row.caseTypeId;
    this.caseSubtype = row.caseSubtype;
    this.appointmentClinicianId = row.appointmentClinicianId;
  }

  static newRecord = ({
    billingKey,
    clinicId,
    locationId,
    type,
    created,
    provider,
    items,
    patient,
    courtesyBilling = false,
    superBill = false,
    tz = ChiroUpDayJsCommon.defaultTimezone,
    transactionDate = Date.now(),
  }: {
    billingKey: string;
    clinicId: number;
    locationId: number;
    type: TransactionTypeEnum;
    created: TransactionActionType;
    provider?: ProviderOnTransactionType;
    items?: PatientTransactionItemType[];
    patient?: PatientOnTransactionType;
    courtesyBilling?: boolean;
    superBill?: boolean;
    tz?: string;
    transactionDate?: number;
  }) => {
    return new this(
      {
        id: -1,
        number: null,
        billingKey,
        clinicId,
        locationId,
        balance: 0,
        courtesyBilling,
        superBill,
        type,
        createdAt: created.ts,
        createdId: created.id,
        updatedAt: null,
        updatedId: null,
        patientSummary: JSON.stringify(patient),
        providerSummary: JSON.stringify(provider),
        transactionDate,
        tz,
      },
      items || [],
    );
  };

  static classFromObject = ({
    transaction,
  }: {
    transaction: PatientTransaction;
  }) => {
    return new this(
      {
        id: transaction.id,
        number: transaction.number,
        transactionDate: transaction.transactionDate || 0,
        clinicId: transaction.clinicId,
        status: transaction.status as AppointmentStatuses,
        taxDirty: transaction.taxDirty ?? false,
        servicesDirty: transaction.servicesDirty ?? false,
        locationId: transaction.locationId,
        billingKey: transaction.billingKey,
        type: transaction.type,
        subtype: transaction.subtype,
        balance: transaction.balance,
        startingBalance: transaction.startingBalance,
        // processingFee: transaction.processingFee,
        createdId: transaction.created.id,
        createdAt: transaction.created.ts,
        updatedAt: transaction.updated.ts,
        updatedId: transaction.updated.id,
        patientSummary: JSON.stringify(transaction.patient),
        providerSummary: JSON.stringify(transaction.provider),
        courtesyBilling: transaction.courtesyBilling,
        superBill: transaction.superBill,
        tz: transaction.tz,
        billingProfileId: transaction.billingProfileId,
        nextRequestedEncounterDate: transaction.nextRequestedEncounterDate,
        verified: transaction.verified,
        verifiedBy: transaction.verifiedBy,
        appointmentClinicianId: transaction.appointmentClinicianId,
      },
      transaction.items,
    );
  };

  static initialFromPatientSummary = (
    patientSummary: TransactionPatientSummaryType,
    user: TransactionActionType,
    patient?: Patient,
  ) => {
    const created = {
      id: user.id,
      displayName: user.displayName || 'Unknown.',
    };
    const provider = {
      id: patientSummary.clinician?.id,
      displayName: patientSummary.clinician?.name,
    };

    return {
      id: -1,
      billingKey: patientSummary.billingKey,
      clinicId: patientSummary.clinicId,
      locationId: patientSummary.locationId || -1,
      transactionDate: patientSummary.appointmentDate,
      balance: 0,
      startingBalance: 0,
      // processingFee: 0,
      type: TransactionTypeEnum.Hybrid,
      subtype: null,
      created,
      updated: created,
      patient: {
        id: patientSummary.id,
        fname: patientSummary?.fname,
        lname: patientSummary?.lname,
        pname: patientSummary?.pname,
        displayName: patientSummary.displayName,
        phone: patientSummary.phone || patient?.phone,
        email: patientSummary.email || patient?.email,
      },
      items: [],
      provider: provider,
      courtesyBilling: false,
      superBill: false,
      caseSubtype: CaseSubType.ActiveTreatment,
    } as any; // PatientTransaction
  };

  /**
   * This creates a special-purpose adhoc transaction for balance transfers
   * all in one fell swoop. It does not save it, it just builds it.
   * ???bwm???
   *
   * @param param0
   * @returns
   */
  static newBalanceTransferTransaction = async ({
    connection,
    clinicId,
    transfer,
    userId,
    patient,
    tz,
  }: {
    connection: Connection;
    clinicId: number;
    transfer: PatientBalanceTransferProps;
    userId: string;
    patient: Patient;
    tz: string;
  }) => {
    const user = await getUser({ connection, userId });
    if (!user) {
      throw new NotFoundError('User not found.');
    }

    const created = {
      id: user.ID,
      displayName: getUserDisplayName(user),
    };

    if (!transfer.amount || transfer.amount <= 0) {
      throw new BadRequestError('Amount must be greater than 0.');
    }

    return {
      id: -1,
      billingKey: v4(),
      clinicId,
      locationId: transfer.locationId || -1,
      // [2024-08-14.1500 by Brian] It might be worth noting that we
      // have a date in the transfer payload, but it is ignored. The
      // UI is readonly as of this date, but that is never certain.
      // So we'd need to validate it if it were to be used.
      transactionDate: dayjs().tz(tz).startOf('day').valueOf(),
      balance: transfer.amount,
      startingBalance: 0,
      // processingFee: 0,
      type: TransactionTypeEnum.AdHoc,
      subtype: TransactionPurchaseSubtypeEnum.BalanceTransfer,
      created,
      updated: created,
      patient: {
        id: patient.ID,
        fname: patient?.fname,
        lname: patient?.lname,
        pname: patient?.pname,
        displayName: getPatientDisplayName(patient),
        phone: patient.phone || patient?.phone,
        email: patient.email || patient?.email,
      },
      items: [
        {
          id: -1,
          patientTransactionId: -1,
          description: transfer.description,
          type: TransactionItemTypeEnum.Debit,
          subtype: TransactionItemSubtypeEnum.BalanceTransfer,
          amount: transfer.amount,
          referenceNumber: transfer.referenceNumber,
          units: 1,
          salesTax: false,
        },
      ],
    } as any; // PatientTransaction
  };

  static initialFromAppointment = (
    appt: AppointmentClass,
    user: TransactionActionType,
    patient?: Patient,
    courtesyBilling = false,
    superBill = false,
  ) => {
    const created = {
      id: user.id,
      displayName: user.displayName || 'Unknown.',
    };
    const provider = {
      id: appt.clinicianId,
      displayName: appt.displayValues.clinicianName,
    };
    return {
      id: -1,
      billingKey: appt.id,
      clinicId: appt.clinicId,
      locationId: appt.locationId,
      balance: 0,
      type: TransactionTypeEnum.Hybrid,
      created,
      courtesyBilling,
      superBill,
      updated: created,
      patient: {
        id: appt.patientId,
        displayName: appt.displayValues.patientName,
      },
      patientSummary: {
        id: appt.patientId,
        fname: patient?.fname,
        lname: patient?.lname,
        pname: patient?.pname,
        displayName: appt.displayValues.patientName,
        phone: appt.displayValues.patientPhone || patient?.phone,
        email: appt.displayValues.patientEmail || patient?.email,
      },
      items: [],
      provider: provider,
    } as any; // PatientTransaction
  };

  static initialFromPayment = (
    payment: PatientPayment,
    clinicId: number,
    user: TransactionActionType,
    credit: boolean,
    primaryTimezone: string,
    payorId?: number | null,
  ) => {
    const billingKey = v4();

    const created = {
      id: user.id,
      ts: Date.now(),
      displayName: user.displayName || 'Unknown.',
    };

    let transactionDate = dayjs().tz(primaryTimezone).startOf('day').valueOf();

    if (typeof payment.paymentDate === 'string') {
      try {
        const convertedDate = dayjs(payment.paymentDate, 'MM/DD/YYYY').format(
          'YYYY-MM-DD',
        );
        transactionDate = (
          createDayjs({
            datetime: convertedDate,
            timezone: 'UTC', // For date-only, we want UTC. No time please.
          }) as Dayjs
        )
          .startOf('day')
          .valueOf();
      } catch (e) {
        // It may be interesting to note that when date parses mm/dd/yyyy, it
        // is just a date. When it parses yyyy-mm-dd, it is a date and time so
        // we need to '00:00:00' at the end to make it leave it alone.
        try {
          transactionDate = dayjs
            .tz(`${payment.paymentDate} 00:00:00`, 'UTC')
            .startOf('day')
            .valueOf();
        } catch (e) {
          console.error(e);
          console.error(`Could not parse date: ${payment.paymentDate}.`);
        }
      }
    }

    const amount = Number(payment.amount) + Number(payment.processingFee || 0),
      items: any = [
        {
          id: -1,
          patientTransactionId: -1,
          description: `Initial payment${amount < 0 ? ' (rollback)' : ''}`,
          type: TransactionItemTypeEnum.Credit,
          subtype: TransactionItemSubtypeEnum.Payment,
          amount,
          units: 1,
          salesTax: 0,
        },
      ];

    const amountsPaid =
      payment.transactionPayments?.reduce(
        (sum, tp) => sum + Number(tp.paymentAmount),
        0,
      ) || 0;

    const final = {
      id: -1,
      number: null,
      billingKey,
      clinicId,
      description: payment.description,
      locationId: payment.locationId,
      // processingFee: payment.processingFee || 0,
      patient: !PayorPaymentTypesHash[payment.type as PaymentType]
        ? {
            id: payment.patientId,
            displayName: '',
          }
        : {
            id: payment.patientId,
            displayName: payment.description,
          },
      balance: payment.amount - amountsPaid,
      startingBalance: payment.amount + (payment.processingFee || 0),
      type: credit ? TransactionTypeEnum.Credit : TransactionTypeEnum.Payment,
      subtype: payment.type,
      amount: payment.amount,
      transactionDate,
      created,
      items,
      ePayRefNum: payment.ePayRefNum,
      ePayBatchKey: payment.ePayBatchKey,
      referenceNumber: payment?.referenceNumber
        ? String(payment?.referenceNumber)
        : null,
      tz: primaryTimezone,
      payorId,
    };

    return final as PatientTransaction;
  };
}

export const toTransactionItemSubtype = (
  subtype: TransactionItemSubtypeEnum | string,
  item: any,
) => {
  switch (subtype) {
    case TransactionItemSubtypeEnum.Treatment: {
      return {
        id: -1,
        patientTransactionId: -1,
        description: item.description,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Debit,
        subtype,
        units: 1,
        salesTax: item.salesTax,
        amount: item.amount,
        compensationRateId: item.compensationRateId || null,
        compensationRate: item.compensationRate || null,
      };
    }
    case TransactionItemSubtypeEnum.PatientWriteOff: {
      return {
        id: -1,
        patientTransactionId: -1,
        description: 'Write-off',
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Credit,
        subtype,
        units: 1,
        amount: item.amount,
      };
    }
    case TransactionItemSubtypeEnum.Supplement: {
      return {
        id: -1,
        patientTransactionId: -1,
        description: item.name,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Debit,
        subtype,
        amount: item.price,
        units: 1,
        groupId: item.groupId,
        salesTax: item.salesTax,
        supplementId: item.ID,
        compensationRateId: item.compensationRateId || null,
        compensationRate: item.compensationRate || null,
      };
    }
    case TransactionItemSubtypeEnum.AdditionalFee: {
      return {
        id: -1,
        patientTransactionId: -1,
        description: item.description,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Debit,
        subtype,
        amount: item.amount,
        units: 1,
        groupId: item.groupId,
        salesTax: 0,
        packageId: item.packageId,
      };
    }
    case TransactionItemSubtypeEnum.Service: {
      return {
        id: -1,
        patientTransactionId: -1,
        code: item.code,
        codeSet: item.codeSet,
        description: item.description,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Debit,
        subtype,
        amount: item.billedAmount,
        units: 1,
        modifier1: item.modifier1,
        modifier2: item.modifier2,
        modifier3: item.modifier3,
        modifier4: item.modifier4,
        compensationRateId: item.compensationRateId || null,
        compensationRate: item.compensationRate || null,
        locked: false,
        groupId: item.groupId,
        salesTax: item.salesTax,
      };
    }
    case TransactionItemSubtypeEnum.Payment: {
      const amount = Number(item.paymentAmount) || Number(item.amount);
      return {
        id: item.id ?? -1,
        patientTransactionId: item.patientTransactionId ?? -1,
        description: `Payment${amount < 0 ? ' (rollback)' : ''}`,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Credit,
        subtype,
        amount,
        units: 1,
        salesTax: 0, //TODO Change this when I know more about tax
        paymentToward: item.paymentToward,
        paymentSource: item.paymentSource,
        displayData: item.displayData ? JSON.stringify(item.displayData) : null,
      };
    }
    case TransactionItemSubtypeEnum.IncentivePayment: {
      return {
        id: item.id ?? -1,
        patientTransactionId: item.patientTransactionId ?? -1,
        description: item.description,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Credit,
        subtype,
        amount: Number(item.paymentAmount) || Number(item.amount),
        units: 1,
        salesTax: 0, //TODO Change this when I know more about tax
        paymentToward: item.paymentToward,
        paymentSource: item.paymentSource,
        displayData: item.displayData ? JSON.stringify(item.displayData) : null,
      };
    }
    case TransactionItemSubtypeEnum.Override: {
      return {
        id: -1,
        patientTransactionId: -1,
        description: item.description,
        type: TransactionItemTypeEnum.Debit,
        subtype,
        amount: item?.amount,
        units: 1,
        salesTax: 0,
        adjustment: item.structure,
      };
    }
    case TransactionItemSubtypeEnum.Adjustment: {
      return {
        id: -1,
        patientTransactionId: -1,
        description: item.description,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Credit,
        subtype,
        amount: item?.amount,
        units: 1,
        salesTax: 0,
        adjustment: item.adjustment ?? null,
        packageId: item?.packageId || null,
      };
    }
    case TransactionItemSubtypeEnum.NoShow: {
      return {
        id: -1,
        patientTransactionId: -1,
        description: item.description,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Debit,
        subtype,
        amount: item.amount,
        units: 1,
        salesTax: 0,
      };
    }
    case TransactionItemSubtypeEnum.Canceled: {
      return {
        id: -1,
        patientTransactionId: -1,
        description: item.description,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Debit,
        subtype,
        amount: item.amount,
        units: 1,
        salesTax: 0,
      };
    }
    case TransactionItemSubtypeEnum.PatientRefund: {
      return {
        id: item.id ?? -1,
        patientTransactionId: item.patientTransactionId ?? -1,
        description: item.description,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Debit,
        subtype,
        amount: item.amount,
        units: 1,
        salesTax: 0,
      };
    }
    case TransactionItemSubtypeEnum.RefundUnappliedPayment: {
      return {
        id: item.id ?? -1,
        patientTransactionId: item.patientTransactionId ?? -1,
        description: item.description,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Credit,
        subtype,
        amount: item.amount,
        units: 1,
        salesTax: 0,
      };
    }
    case TransactionItemSubtypeEnum.AppliedPayment: {
      return {
        id: item.id ?? -1,
        patientTransactionId: item.patientTransactionId ?? -1,
        description: `Payment${item.paymentAmount < 0 ? ' (rollback)' : ''}`,
        type: TransactionItemTypeEnum.Debit,
        subtype: TransactionItemSubtypeEnum.AppliedPayment,
        amount: item.paymentAmount,
        taxAmount: item.taxAmount,
        units: 1,
        salesTax: false,
        paymentToward: item.paymentToward,
        paymentSource: item.paymentSource,
        compensationAmount: item.compensationAmount || 0,
        createdAt: item.createdAt,
      };
    }
    case TransactionItemSubtypeEnum.PayorWriteOff: {
      return {
        id: -1,
        patientTransactionId: -1,
        description: 'Claims Write-off',
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Credit,
        subtype,
        amount: item.amount,
        units: 1,
      };
    }
    case TransactionItemSubtypeEnum.BalanceAllocatedToPatient: {
      return {
        id: -1,
        patientTransactionId: -1,
        description: 'Balance Allocated to Patient',
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Debit,
        subtype,
        amount: item.amount,
        units: 1,
      };
    }
    case TransactionItemSubtypeEnum.Package: {
      return {
        id: item?.id ?? -1,
        patientTransactionId: -1,
        description: item.name,
        type: item.void
          ? TransactionItemTypeEnum.Void
          : TransactionItemTypeEnum.Debit,
        subtype,
        amount: item.price,
        units: 1,
        salesTax: item.collectTax,
        packageId: item.packageId,
        packageDate: item.packageDate,
      };
    }
  }

  return item;
};

export class TransactionActionClass {
  id: string;
  ts?: number | null;
  displayName?: string;

  constructor(action: TransactionActionType) {
    this.id = action.id || '- unknown -';
    this.ts = action.ts;
    this.displayName = action.displayName || '- unknown -';
  }
}

/**
 * Service line items can only be added via the encounter coding
 * tool.
 */
const vals = Object.values(TransactionItemSubtypeEnum).filter(
  (k) => k !== 'Service' && k !== 'Payment',
);
export const TransactionItemSubtypeOptions = Object.keys(
  TransactionItemSubtypeEnum,
)
  .filter((key) => key !== 'Service' && key !== 'Payment')
  .map((key, idx) => ({ text: vals[idx], value: key }));

/**
 * This is a SUBSET of the fields to build a transaction with the
 * extra details just for the patient. The duplicate fields in the
 * transaction are not included.
 */

export const TransactionCreditMethodOptions = Object.keys(
  TransactionCreditMethodEnum,
).map((key) => ({ text: key, value: key }));

// const vals2 = Object.values(TransactionItemSubtypeEnum).filter(
//   (k) =>
//     k !== 'Service' && k !== 'Canceled' && k !== 'No-Show' && k !== 'Payment',
// );
export const AdHocTransactionItemOptions = Object.keys(
  TransactionItemSubtypeEnum,
)
  .filter((key) => {
    return (
      key !== 'Service' &&
      key !== 'Canceled' &&
      key !== 'NoShow' &&
      key !== 'Payment'
    );
  })
  .map((key) => {
    const keyTyped = key as keyof typeof TransactionItemSubtypeEnum;
    return {
      text: itemSubtypeRename[keyTyped] || TransactionItemSubtypeEnum[keyTyped],
      value: key,
    };
  });

export type TransactionList = {
  itemName: string;
  purchaseTotal: number;
  purchaseBalance: number;
  patientBalance: number;
  insurancePolicies: string[];
  invoiceStatuses: string[];
  billingKey: string;
};
